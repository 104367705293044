<template>
  <div class="content md" v-if="loading">
    <div class="dharma-assembly-loading">
      <a-spin tip="加载中" />
    </div>
  </div>
  <buddhism-order :temp="temp" v-else />
</template>

<script lang="tsx">
    import BuddhismOrder from "./BuddhismOrder";

    export default {
        name: "DharmaAssembly",
        components: {
            BuddhismOrder,
        },
        data() {
            return {
                temp: null,
                loading: false,
            }
        },
        computed: {
            id() {
                return this.$route.params.id
            }
        },
        created() {
            this.getTemp();
        },
        methods: {
            getTemp() {
                const temp = this.$store.getters. buddhaTemp;
                if (temp && temp.id == this.id) {
                    this.temp = temp;
                } else {
                    this.loading = true;
                    this.$axios(`/admin/fs-temp/${this.id}`).then(res => {
                        this.temp = res;
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            },
        }
    }
</script>

<style scoped>
  .dharma-assembly-loading {
    margin-top: 100px;
    text-align: center;
  }
</style>
